.biz-footer-temp8::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: -69px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0px 71px 58px 0px;
    border-color: transparent transparent #3e3e3e transparent;
}