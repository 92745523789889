.biz-mobile1-temp6::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: -24px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0px 24px 33px 33px;
    border-color: transparent transparent #ec1d25 transparent;
}

.biz-mobile1-temp6::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: -33px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0px 24px 33px 33px;
    border-color: transparent transparent #ec1d25 transparent;
}

.biz-web-temp6::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: -30px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 30px 30px 30px;
    border-color: transparent transparent #ec1d8b transparent;
}

.biz-mail-temp6::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: -30px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 30px 30px 30px;
    border-color: transparent transparent #0056a2 transparent;
}